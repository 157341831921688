import React from 'react';
import { Provider } from 'react-redux';
import { theme } from '@weirgroup/weir-dls';
import { ThemeProvider } from 'styled-components';
import { MsalProvider } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { detect } from 'detect-browser';
import { createRoot } from 'react-dom/client';
import { msalInstance } from './services/msalConfig';
import Main from './main/main/index';
import store from './store';
import './i18n';

// MSAL configuration
msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const browser = detect();

  const AppContent = () => {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={msalInstance}>
            <Main />
          </MsalProvider>
        </ThemeProvider>
      </Provider>
    );
  };

  const renderApp = () => {
    createRoot(document.getElementById('root')).render(
      <div>
        {/* <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Main />
        </Provider>
      </ThemeProvider> */}
        <AppContent />
      </div>
    );
  };

  const renderBrowserNotSupportedError = () => {
    createRoot(document.getElementById('root')).render(
      <div>
        This browser <span>{`(${browser.name} ${browser.version})`}</span> is
        not supported. Please use a modern browser like{' '}
        <a href="https://www.google.com/chrome/">Chrome</a>
      </div>
    );
  };

  const supportBrowsers = [
    'chrome',
    'firefox',
    'edge',
    'opera',
    'safari',
    'ios',
    'crios',
    'ie',
    'edge-chromium',
  ];

  if (browser) {
    if (supportBrowsers.indexOf(browser.name) > -1) {
      if (
        browser.name === 'ie' &&
        parseInt(browser.version.split('.')[0], 10) < 11
      ) {
        renderBrowserNotSupportedError();
      } else {
        renderApp();
      }
    } else {
      renderBrowserNotSupportedError();
    }
  }
});
