import React, { useEffect } from 'react';
import styles from '../Footer/main.module.css';
import { fetchTermsCondtionsCMSData } from './TermsConditionsAction';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { Typography, Grid } from '@weirgroup/weir-dls';
import { applicationRouteConstants } from '../../shared/_constants';
import Loader from '../../components/Loader/Loader';

/* This generates the Terms & Conditions templates */
export default function TermsConditions() {
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();
  const { termsConditionsCMSData, isLoader } = useSelector(
    (state) => state.termsConditionsReducer
  );
  // const contentArea =
  //   termsConditionsCMSData?.contentArea?.[0]?.contentLink?.expanded?.text
  //     ?.structure?.children || [];

  const contentArea =
    termsConditionsCMSData[0]?.contentArea[0]?.contentLink?.expanded?.rtfText
      ?.structure?.children || [];

  useEffect(() => {
    const getTokenAndFetchData = async () => {
      dispatch(fetchTermsCondtionsCMSData());
    };
    getTokenAndFetchData();
  }, [accounts, instance, dispatch]);

  return (
    <div>
      {isLoader && <Loader />}
      {!isLoader && (
        <Grid desktopcolumns={8} tabletcolumns={8} mobilecolumns={4}>
          <Typography variant="H1" color="onyx">
            <div className={styles.footerPagesContainer} data-testid="TC_Line1">
              {contentArea?.[0]?.children?.[0]?.text}
            </div>
          </Typography>
          <div className={styles.headingParaSpacing} id="TC_Line2">
            <Typography variant="P1" color="onyx">
              {contentArea?.[1]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line3">
            <Typography variant="H4" color="onyx">
              {contentArea?.[2]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line4">
            <Typography variant="P1" color="onyx">
              {contentArea?.[3]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line5">
            <Typography variant="H4" color="onyx">
              {contentArea?.[4]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line6">
            <Typography variant="P1" color="onyx">
              {contentArea?.[5]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line7">
            <Typography variant="P1" color="onyx">
              <ul className={styles.ListLeftPadding}>
                <li>
                  {contentArea?.[6]?.children?.[0]?.children?.[0]?.text}
                  <a
                    href={`${applicationRouteConstants.PRIVACYPOLICY}`}
                    alt=""
                    rel="noreferrer"
                    className={styles.redirectionLink}
                  >
                    {
                      contentArea?.[6]?.children?.[0]?.children?.[1]
                        .children?.[0]?.text
                    }
                  </a>
                  {contentArea?.[6]?.children?.[0]?.children?.[2]?.text.replace(
                    /&nbsp;/g,
                    ' '
                  )}
                </li>
                <li>
                  {contentArea?.[6]?.children?.[1]?.children?.[0]?.text}
                  <a
                    href={`${applicationRouteConstants.COOKIESPOLICY}`}
                    alt=""
                    rel="noreferrer"
                    className={styles.redirectionLink}
                  >
                    {
                      contentArea?.[6]?.children?.[1]?.children?.[1]
                        .children?.[0]?.text
                    }
                  </a>
                  {contentArea?.[6]?.children?.[1]?.children?.[2]?.text}
                </li>
              </ul>
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line8">
            <Typography variant="H4" color="onyx">
              {contentArea?.[7]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line9">
            <Typography variant="P1" color="onyx">
              {contentArea?.[8]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line10">
            <Typography variant="P1" color="onyx">
              {contentArea?.[9]?.children?.[0]?.text}
              <a
                href={contentArea?.[9]?.children?.[1]?.url}
                target="_blank"
                alt=""
                rel="noreferrer"
                className={styles.redirectionLink}
              >
                {contentArea?.[9]?.children?.[1]?.children?.[0]?.text}
              </a>
              {contentArea?.[9]?.children?.[2]?.text}
              <a
                href={contentArea?.[9]?.children?.[3]?.url}
                target="_blank"
                alt=""
                rel="noreferrer"
                className={styles.redirectionLink}
              >
                {contentArea?.[9]?.children?.[3]?.children?.[0]?.text}
              </a>
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line11">
            <Typography variant="H4" color="onyx">
              {contentArea?.[10]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line12">
            <Typography variant="P1" color="onyx">
              {contentArea?.[11]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line13">
            <Typography variant="P1" color="onyx">
              {contentArea?.[12]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line14">
            <Typography variant="H4" color="onyx">
              {contentArea?.[13]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line15">
            <Typography variant="P1" color="onyx">
              {contentArea?.[14]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line16">
            <Typography variant="H4" color="onyx">
              {contentArea?.[15]?.children?.[0]?.text}
              <a
                href={contentArea?.[15]?.children?.[1]?.url}
                target="_blank"
                alt=""
                rel="noreferrer"
                className={styles.redirectionLink}
              >
                {contentArea?.[15]?.children?.[1]?.children?.[0]?.text}
              </a>
              {contentArea?.[15]?.children?.[2]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line17">
            <Typography variant="P1" color="onyx">
              {contentArea?.[16]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line18">
            <Typography variant="P1" color="onyx">
              {contentArea?.[17]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line19">
            <Typography variant="P1" color="onyx">
              {contentArea?.[18]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line20">
            <Typography variant="P1" color="onyx">
              {contentArea?.[19]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line21">
            <Typography variant="H4" color="onyx">
              {contentArea?.[20]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line22">
            <Typography variant="P1" color="onyx">
              {contentArea?.[21]?.children?.[0]?.text
                .replace(/&nbsp;/g, ' ')
                .replace(/&rsquo;/g, "'")}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line23">
            <Typography variant="H4" color="onyx">
              {contentArea?.[22]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line24">
            <Typography variant="P1" color="onyx">
              {contentArea?.[23]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line25">
            <Typography variant="P1" color="onyx">
              {contentArea?.[24]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line26">
            <Typography variant="P1" color="onyx">
              {contentArea?.[25]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line27">
            <Typography variant="P1" color="onyx">
              {contentArea?.[26]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line28">
            <Typography variant="P1" color="onyx">
              {contentArea?.[27]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line29">
            <Typography variant="H4" color="onyx">
              {contentArea?.[28]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line30">
            <Typography variant="P1" color="onyx">
              {contentArea?.[29]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line31">
            <Typography variant="P1" color="onyx">
              <ul className={styles.ListLeftPadding}>
                <li>{contentArea?.[30]?.children?.[0]?.children?.[0]?.text}</li>
                <li>{contentArea?.[30]?.children?.[1]?.children?.[0]?.text}</li>
              </ul>
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line32">
            <Typography variant="P1" color="onyx">
              {contentArea?.[31]?.children?.[0]?.text.replace(/&rsquo;/g, "'")}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line33">
            <Typography variant="P1" color="onyx">
              {contentArea?.[32]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line34">
            <Typography variant="H4" color="onyx">
              {contentArea?.[33]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line35">
            <Typography variant="P1" color="onyx">
              {contentArea?.[34]?.children?.[0]?.text.replace(/&rsquo;/g, "'")}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line36">
            <Typography variant="P1" color="onyx">
              {contentArea?.[35]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line37">
            <Typography variant="H4" color="onyx">
              {contentArea?.[36]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line38">
            <Typography variant="P1" color="onyx">
              {contentArea?.[37]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line39">
            <Typography variant="P1" color="onyx">
              {contentArea?.[38]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line40">
            <Typography variant="P1" color="onyx">
              {contentArea?.[39]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line41">
            <Typography variant="P1" color="onyx">
              {contentArea?.[40]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div id="TC_Line42">
            <Typography variant="P1" color="onyx">
              <ul className={styles.ListLeftPadding}>
                <li>{contentArea?.[41]?.children?.[0]?.children?.[0]?.text}</li>
                <li>{contentArea?.[41]?.children?.[1]?.children?.[0]?.text}</li>
                <li>{contentArea?.[41]?.children?.[2]?.children?.[0]?.text}</li>
                <li>{contentArea?.[41]?.children?.[3]?.children?.[0]?.text}</li>
              </ul>
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line43">
            <Typography variant="P1" color="onyx">
              {contentArea?.[42]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line44">
            <Typography variant="P1" color="onyx">
              {contentArea?.[43]?.children?.[0]?.text.replace(/&rsquo;/g, "'")}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line45">
            <Typography variant="H4" color="onyx">
              {contentArea?.[44]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line46">
            <Typography variant="P1" color="onyx">
              {contentArea?.[45]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line47">
            <Typography variant="P1" color="onyx">
              {contentArea?.[46]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line48">
            <Typography variant="P1" color="onyx">
              {contentArea?.[47]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line49">
            <Typography variant="P1" color="onyx">
              {contentArea?.[48]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line50">
            <Typography variant="P1" color="onyx">
              <ul className={styles.ListLeftPadding}>
                <li>{contentArea?.[49]?.children?.[0]?.children?.[0]?.text}</li>
                <li>{contentArea?.[49]?.children?.[1]?.children?.[0]?.text}</li>
                <li>{contentArea?.[49]?.children?.[2]?.children?.[0]?.text}</li>
                <li>{contentArea?.[49]?.children?.[3]?.children?.[0]?.text}</li>
                <li>
                  {contentArea?.[49]?.children?.[4]?.children?.[0]?.text
                    .replace(/&nbsp;/g, ' ')
                    .replace(/&lsquo;/g, "'")
                    .replace(/&rsquo;/g, "'")}
                </li>
                <li>{contentArea?.[49]?.children?.[5]?.children?.[0]?.text}</li>
                <li>{contentArea?.[49]?.children?.[6]?.children?.[0]?.text}</li>
                <li>{contentArea?.[49]?.children?.[7]?.children?.[0]?.text}</li>
                <li>
                  {contentArea?.[49]?.children?.[8]?.children?.[0]?.text.replace(
                    /&nbsp;/g,
                    ' '
                  )}
                </li>
                <li>{contentArea?.[49]?.children?.[9]?.children?.[0]?.text}</li>
                <li>
                  {contentArea?.[49]?.children?.[10]?.children?.[0]?.text}
                </li>
              </ul>
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line51">
            <Typography variant="P1" color="onyx">
              {contentArea?.[50]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line52">
            <Typography variant="H4" color="onyx">
              {contentArea?.[51]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line53">
            <Typography variant="P1" color="onyx">
              {contentArea?.[52]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line54">
            <Typography variant="H4" color="onyx">
              {contentArea?.[53]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line55">
            <Typography variant="P1" color="onyx">
              {contentArea?.[54]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
            </Typography>
          </div>
          <div className={styles.paraTopSpacing} id="TC_Line56">
            <Typography variant="H4" color="onyx">
              {contentArea?.[55]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.headingParaSpacing} id="TC_Line57">
            <Typography variant="P1" color="onyx">
              {contentArea?.[56]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line58">
            <Typography variant="P1" color="onyx">
              {contentArea?.[57]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line59">
            <Typography variant="P1" color="onyx">
              {contentArea?.[58]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line60">
            <Typography variant="P1" color="onyx">
              {contentArea?.[59]?.children?.[0]?.text.replace(/&nbsp;/g, ' ')}
              <a
                href={contentArea?.[59]?.children?.[1]?.url}
                target="_blank"
                alt=""
                rel="noreferrer"
                className={styles.redirectionLink}
              >
                {contentArea?.[59]?.children?.[1]?.children?.[0]?.text}
              </a>
              {contentArea?.[59]?.children?.[2]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line61">
            <Typography variant="P1" color="onyx">
              {contentArea?.[60]?.children?.[0]?.text}
            </Typography>
          </div>
          <div className={styles.paraSpacing} id="TC_Line62">
            <Typography variant="P1" color="onyx">
              {contentArea?.[61]?.children?.[0]?.text}
            </Typography>
          </div>
        </Grid>
      )}
    </div>
  );
}
