const SERVICE_CONSTANTS = {
  apiBaseURL: '/api/identity/iamservice',
  getApplicationData: '/applications/me',
  loginSignUpBaseURL: '/api/identity/iamservice',
  signUp: '/Users',
  validateUser: '/users/',
  myAccess: '/user/access',
  moreApps: '/user/access-request/assignable-applications',
  myActivity: '/Users/Activities',
  headersAcceptv1: `application/json; application/${process.env.REACT_APP_CDE_IAMSERVICE_VERSION}${process.env.REACT_APP_CDE_HEADER_VERSION1}`,
  headersAcceptv2: `application/json; application/${process.env.REACT_APP_CDE_IAMSERVICE_VERSION}${process.env.REACT_APP_CDE_HEADER_VERSION2}`,
  openAPIBaseURL: 'https://apis.apps.weir/iamservice',
  appsignUp: '/user/access-request',
  moreAppsSubmit: '/user/access-request/assign-applications',
  homePage:
    '/api/episerver/v3.0/content/?contentUrl=%2F&matchExact=true&expand=*',
  homePageNews:
    '/api/news/search/?ps=3&old=false&type=Blog Post&type=Financial Results&type=News Article',
  applicationsPage:
    '/api/episerver/v3.0/content/?contentUrl=applications&matchExact=true&expand=*',
  moreAppsCmsContent: '/api/episerver/v3.0/content/',
  cmsImagesEndPoint: '/children?expand=applogo,image,featuredapp,apptype',
  termsConditionsPage:
    '/api/episerver/v3.0/content/?contentUrl=terms-of-use&&matchExact=true&expand=*',
  cookiePolicyPage:
    '/api/episerver/v3.0/content/?contentUrl=cookies-policy&matchExact=true&expand=*',
  privacyPolicyPage:
    '/api/episerver/v3.0/content/?contentUrl=privacy-statement&matchExact=true&expand=*',
  footerLinks:
    '/api/episerver/v3.0/content/?contentUrl=homepage&matchExact=true&expand=Footermenu4',
  cookiePolicyTableContent: '/api/table/22032/',
  getContentIdForImages: '/api/episerver/v3.0/content/?contentUrl=portal-weir',
};
export default SERVICE_CONSTANTS;
