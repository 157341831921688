/* eslint-disable no-unused-vars */
import {
  PRIVACYPOLICY_CMS_DATA,
  SHOW_LOADER,
} from './PrivacyPolicyActionTypes';
import SERVICE_CONSTANTS from '../../shared/_constants/services.constants';
import axios from 'axios';
import { serviceFactory } from '../../services/_helpers/serviceFactory';

export const setPrivacyPolicyCMSData = (data) => {
  return {
    type: PRIVACYPOLICY_CMS_DATA,
    payload: data,
  };
};

export function showLoader(isLoader) {
  return {
    type: SHOW_LOADER,
    isLoader,
  };
}

export const fetchPrivacyPolicyCMSData = () => {
  return (dispatch) => {
    dispatch(showLoader(true));
    const appendUrl = SERVICE_CONSTANTS.privacyPolicyPage;
    const privacyPolicyApi = `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_GLOBAL}${appendUrl}`;
    axios
      .get(privacyPolicyApi, {
        mode: 'no-cors',
        headers: {
          'Accept-Language': 'en',
        },
      })
      .then((response) => {
        dispatch(showLoader(false));
        dispatch(setPrivacyPolicyCMSData(response.data));
      })
      .catch((err) => {
        dispatch(showLoader(false));
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};
