/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Route, Switch, Router, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Notification, IamNav, Grid } from '@weirgroup/weir-dls';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { msalConstants } from '../../services/msal.constants';
import { history } from '../../services/_helpers';
import styles from './main.module.css';
import { applicationRouteConstants } from '../../shared/_constants';
import '../../resources/stylesheets/main.css';
import SiteInformation from '../../modules/SiteInformation';
import PrivateRoute from './protectedRoute';
import Loader from '../../components/Loader/Loader';
import { localStorageOperation } from '../../shared/Helpers/localStorageData';
import { localStorageKeys } from '../../shared/_constants/localStorageKeys';
import { notificationActions } from '../../shared/_actions';
import FooterComponent from '../../components/Footer';
import TermsConditions from '../../components/TermsConditions';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import CookiesPolicy from '../../components/CookiesPolicy';

const LoginPage = lazy(() => import('../../modules/Login'));
const SignUpPage = lazy(() => import('../../modules/SignUp'));
const AppSignUp = lazy(() => import('../../modules/AppSignUp'));
const ErrorPage = lazy(() => import('../../modules/ErrorPage/ErrorPage'));
const SelectionPage = lazy(() => import('../../modules/SelectionPage'));

const Home = lazy(() => import('../../modules/Home'));
const Applications = lazy(() => import('../../modules/Applications'));

const ApplicationRoutes = () => {
  const location = useLocation();
  const appID = window.location.search.indexOf('app_id');
  const [autoPageHeight, setAutoPageHeight] = useState(false);
  useEffect(() => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/' ||
      location.pathname === '/selection-page'
    ) {
      if (!autoPageHeight) setAutoPageHeight(true);
    } else if (autoPageHeight) {
      setAutoPageHeight(false);
    }
  }, [location, autoPageHeight]);

  return (
    <div
      className={`${styles.contentMain} mainHeight ${
        autoPageHeight ? styles.heightAuto : ''
      }`}
    >
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route
            path={`${applicationRouteConstants.LOGIN}`}
            component={LoginPage}
          />
          <Route
            path={`${applicationRouteConstants.SIGNUP}`}
            component={appID >= 0 ? AppSignUp : SignUpPage}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.SiteInformation}`}
            component={SiteInformation}
          />

          <PrivateRoute
            path={`${applicationRouteConstants.HOME}`}
            component={Home}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.APPLICATIONS}`}
            component={Applications}
          />
          <Route
            path={`${applicationRouteConstants.TERMSCONDITIONS}`}
            component={TermsConditions}
          />
          <Route
            path={`${applicationRouteConstants.PRIVACYPOLICY}`}
            component={PrivacyPolicy}
          />
          <Route
            path={`${applicationRouteConstants.COOKIESPOLICY}`}
            component={CookiesPolicy}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.SELECTION_PAGE}`}
            component={SelectionPage}
          />
          <Route component={ErrorPage} />
        </Switch>
      </Suspense>
    </div>
  );
};

const Main = ({ notification, hideNotification, showNotification, t }) => {
  const { accounts, instance } = useMsal();
  const [userToken, setUserToken] = useState(localStorage.getItem('meToken'));
  const detectPopupBlocker = () => {
    const popup = window.open('', '', 'width=100,height=100');
    try {
      popup.close();
    } catch (e) {
      showNotification(
        'alert',
        'Pop-ups blocked',
        'Please update the browser settings to allow pop-ups'
      );
    }
  };

  useEffect(() => {
    window.onpopstate = () => {
      window.location.assign(window.location.pathname);
    };
    // detectPopupBlocker();

    let timeoutId;
    if (notification.showNotification) {
      timeoutId = setTimeout(() => {
        if (notification.showNotification) {
          hideNotification();
          notification.onClose?.();
        }
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [notification, hideNotification]);

  const isAuthenticatedUser = () => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/login' ||
      window.location.pathname === '/register'
    ) {
      return false;
    } else {
      return true;
    }
  };

  const { homePageData } = useSelector((state) => state.homePageReducer);
  const showIamPortal = () => {
    if (homePageData.map((item) => item?.displayName === 'IAM Portal')) {
      return true;
    } else {
      return false;
    }
  };

  const showApplicationNavigation = () => {
    if (
      window.location.pathname === applicationRouteConstants.LOGIN ||
      window.location.pathname === applicationRouteConstants.SIGNUP ||
      window.location.pathname === applicationRouteConstants.SELECTION_PAGE
    ) {
      return false;
    }
    const userInfo = instance.getAccountByUsername(
      localStorageOperation.getDataFromLocalStorage(localStorageKeys.userName)
    );

    // Simplified return statement
    return !(userInfo === null || userInfo === undefined);
  };

  showApplicationNavigation();

  const clickSignUpBtn = () => {
    if (accounts.length > 0) {
      window.localStorage.setItem('clickSignUpBtn', 'true');
      instance.logoutRedirect({
        postLogoutRedirectUri: '/login', // Redirect to register page after logout
      });
    } else {
      window.localStorage.removeItem('clickSignUpBtn');
      window.localStorage.clear();
      sessionStorage.clear();
      // Clear cookies
      document.cookie.split(';').forEach((c) => {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });
      const loginRequest = {
        scopes: ['openid', 'profile', 'user.read'],
        prompt: 'create',
        redirectUri: `${window.location.origin}`,
      };

      instance.loginRedirect(loginRequest).catch((error) => {
        console.error('Authentication error:', error);
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.localStorage.getItem('clickSignUpBtn') === 'true') {
      clickSignUpBtn();
    }
  }, []);

  const [loginImg, setloginImg] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === '/login' ||
      window.location.pathname === '/'
    ) {
      setloginImg(true);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setUserToken(localStorage.getItem('meToken'));
    };

    // Listen for storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const accountInfo = {
    buttonLabelLogout: 'Logout',
    isAdminAccess: { showIamPortal },
    linksLabel: 'Links',
    visitGlobalWeir: 'Visit global.weir',
  };

  const supportItems = {
    serviceNowURL: process.env.REACT_APP_CDE_SERVICE_NOW_URL,
    assignmentGroup: process.env.REACT_APP_CDE_ASSIGNMENT_GROUP,
    callerID: process.env.REACT_APP_CDE_CALLER_ID,
    serviceNowKey: process.env.REACT_APP_CDE_SERVICE_NOW_KEY,
  };

  const InAppItems = [
    {
      id: '1',
      name: 'Home',
      link: '/home',
      navigationOrder: 1,
      InAppMenu: 'Home',
      Href: '/home',
      openNewWindow: false,
    },
    {
      id: '2',
      name: 'Applications',
      link: '/applications',
      navigationOrder: 2,
      InAppMenu: 'Applications',
      Href: '/applications',
      openNewWindow: false,
    },
    {
      id: '3',
      name: 'News',
      link: '/news',
      navigationOrder: 3,
      InAppMenu: 'News',
      Href: '/newsroom/global-news/',
      openNewWindow: true,
    },
  ];

  return (
    <Router history={history}>
      <div>
        <div className={styles.background}>
          <div
            className={
              isAuthenticatedUser() ? styles.iamNavAuth : styles.iamNavUnAuth
            }
          >
            {
              <IamNav
                appId={msalConstants.portalApplicationConfig.clientID}
                history={history}
                inAppNav
                contactTitle={t('contactForm.contactTitle')}
                contactAppname={t('contactForm.contactAppname')}
                subjectRequired={t('contactForm.subjectRequired')}
                subjectLabel={t('contactForm.subjectLabel')}
                subjectPlaceholder={t('contactForm.subjectPlaceholder')}
                messageRequired={t('contactForm.messageRequired')}
                messageLabel={t('contactForm.messageLabel')}
                messagePlaceholder={t('contactForm.messagePlaceholder')}
                buttonLabelClose={t('contactForm.buttonLabelClose')}
                buttonLabelSubmit={t('contactForm.buttonLabelSubmit')}
                contactDescription={t('contactForm.contactDescription')}
                notificationTitleSuccess={t(
                  'contactForm.notificationTitleSuccess'
                )}
                notificationTitleError={t('contactForm.notificationTitleError')}
                notificationTextContent={t(
                  'contactForm.notificationTextContent'
                )}
                redirectUri={`${window.location.origin}/home`}
                showAccount={true}
                showSupportMenu={true}
                showContactMenu={false}
                displayApps={true}
                showMenuhomeLink={false}
                supportFormItems={supportItems}
                accountInfo={accountInfo}
                inAppNavItems={InAppItems}
                applicationName="Customer Portal"
                portalweirApiBaseurlPrep={
                  process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL
                }
                baseUrl={process.env.REACT_APP_CDE_BASEURLV2}
                apiBaseURLGlobal={
                  process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_GLOBAL
                }
                iamServiceVersion={process.env.REACT_APP_CDE_IAMSERVICE_VERSION}
                headerVersion={process.env.REACT_APP_CDE_HEADER_VERSION2}
                userToken={userToken}
                userInstance={instance}
                onGlobalSignUpClick={clickSignUpBtn}
              />
            }
          </div>
          {notification.showNotification && (
            <div className={styles.notification}>
              <Notification
                variant={notification.notificationType}
                title={notification.notificationTitle}
                onClose={() => {
                  hideNotification();
                  notification.onClose?.();
                }}
                textContent={notification.notificationMessage}
                callToActionList={notification.callToActionList}
              />
            </div>
          )}
          {isAuthenticatedUser() ? (
            <UnauthenticatedTemplate>
              <div className={styles.grid}>
                <Grid desktopcolumns={12} tabletcolumns={8} mobilecolumns={4}>
                  <ApplicationRoutes />
                </Grid>
              </div>
            </UnauthenticatedTemplate>
          ) : (
            <UnauthenticatedTemplate>
              <ApplicationRoutes />
            </UnauthenticatedTemplate>
          )}
          <AuthenticatedTemplate>
            {loginImg ? (
              <ApplicationRoutes />
            ) : (
              <div className={styles.grid}>
                <Grid desktopcolumns={12} tabletcolumns={8} mobilecolumns={4}>
                  <ApplicationRoutes />
                </Grid>
              </div>
            )}
          </AuthenticatedTemplate>
          <FooterComponent />
        </div>
      </div>
    </Router>
  );
};

Main.propTypes = {
  t: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  notificationType: PropTypes.func.isRequired,
  notificationMessage: PropTypes.func.isRequired,
  callToActionList: PropTypes.func.isRequired,
  notificationTitle: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => {
      dispatch(notificationActions.hideNotification());
    },
    showNotification: (type, title, message) => {
      dispatch(
        notificationActions.showNotification({
          notificationType: type,
          notificationTitle: title,
          notificationMessage: message,
        })
      );
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Main));
