import {
  PRIVACYPOLICY_CMS_DATA,
  SHOW_LOADER,
} from './PrivacyPolicyActionTypes';

const initialState = {
  privacyPolicyCMSData: [],
  isLoader: false,
};

export const privacyPolicyReducer = (state = initialState, action) => {
  if (action.type === PRIVACYPOLICY_CMS_DATA) {
    return {
      ...state,
      privacyPolicyCMSData: action.payload,
    };
  } else if (action.type === SHOW_LOADER) {
    return {
      ...state,
      isLoader: action.isLoader,
    };
  } else {
    return state;
  }
};
